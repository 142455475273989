



































































































































































import { Component, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import * as Models from "@gigalot/data-models";
import lodash from "lodash";
import Decimal from "decimal.js";
import { createClient } from "@/helpers/graphql-ws-rtc-adapter";
const Subscriber = require("@jetblack/graphql-reconnect-client");


@Component({
  components: {
    DimssaButton
  }
})
export default class CribsSummaryPlan extends Vue {
  cribScores: Models.CribReadingItem[] = [];
  cribHistory:any[] = [];
  cribScoresToUpload = 0;
  cribReadingAdjustments: Models.CribReadingAdjustments = new Models.CribReadingAdjustments();
  rationPlan: Models.RationPlan = new Models.RationPlan();
  feedPlan: any = {};
  search = "";
  loading = true;
  loadingHistory = true;
  tableKey = 0;
  snack = false;
  snackColor = "";
  snackText = "";
  errorText = "";
  errorDialog = false;
  callIncrement = 1;
  useAutoCribAdjustments = true;
  useCribAliases = true;
  rations: any[] = [];
  gradient= ['red', 'orange', 'yellow','green']
  headers = [
    {
      text: "Kraal",
      value: "kraalId",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Ration",
      value: "ration",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Score",
      value: "score",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Call",
      value: "call",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {  
      text: "Feed History",
      value: "amountFed",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Allocated (kg)",
      value: "sequence",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
     {
      text: "Status",
      value: "status",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
  ];


  async mounted() {
    this.cribScoresToUpload = (await this.$store.dispatch("data/getCribScores")).filter((a:any)=>{if (!a.uploaded) return a;}).length;
    //check if any uploadable
    this.$store.dispatch("upload/upload", undefined, { root: true });
    //then upload
     this.$store.commit("popup/displayYesNo", {
      message: "Do you want to download new crib scores for the day?",
      yesAction: async () => {
        this.$store.dispatch("user/addFirebaseCallback", this.getCribPlans);
        this.$store.dispatch("user/addFirebaseCallback", this.getCribPlanHistory);
        this.$store.dispatch("user/addFirebaseCallback", this.getCribPlansSocket);
        },
        noAction: async () => {
          //load from existing idb data
           this.$store.dispatch("user/addFirebaseCallback", this.getCribPlansDb);
        }
     });
  }

  get themeClass(): string {
    return this.$store.state.lightDarkMode;
  }

  destroyed() {
    if (this.graphQLUnsubscribe) {
      this.graphQLUnsubscribe();
    }
    if (this.graphQLSubscriber) {
      this.graphQLSubscriber();
    }
  }
  urlws = "wss://pi.gigalot.systems:7778/api/ws";
  options = {};

  subscriptions = `subscription($guid: String!) {
         cribScores(guid: $guid) {
          typename
          guid
          date
          cribScores{
            typename
            guid
            score
            kraalId
            kraalGuid
            readingDate
            call
            status
            ration
            rationGuid
            sequence
            actualFed
            color
            gender
            animalCount
            notes {
              typename
              guid
              timestamp
              text
            }
          }
        }
       
    }`;

  variables = {guid:this.$store.state.user.location.guid};
  operationName = null;
  page = 1;
  forceRerender() {
    let pageHolder = this.page;
    console.log(pageHolder);
    this.tableKey += 1;
    this.page = pageHolder;
    console.log(pageHolder);
  }

  getColor(status: string) {
    switch (status) {
      case "waiting":
        return "orange";
      case "skipped":
        return "red";
      case "blocked":
        return "grey";
      case "done":
        return "green";
    }
  }

  getAllocationAmount(item:Models.CribReadingItem){
    if (item.animalCount && item.call)
    {
      let a = new Decimal(item.call);
      let b = new Decimal(item.animalCount);
      let c = a.mul(b);
    
    return c.toFixed(0);
    }
    else return 0;
  }

  getDryMatterFactor(rationGuid:string){
    for (let i = 0; i < this.rations.length;i++){
      if (this.rations[i].guid === rationGuid){
        return this.rations[i].dryMatterFactor?.dryMatterFactor ? this.rations[i].dryMatterFactor.dryMatterFactor : 1.0;
      }
    }
    return 1.0;
  }

  getAlias(scored:number){

    if (this.useCribAliases){
      let alias:any = lodash.find(this.cribReadingAdjustments.cribReadingAdjustment, (yes)=>{
        if (yes.score == scored)
        return yes;});
      if (!alias) {
        return "unscored";
      }
        return alias?.userAlias;
    }
    else return scored;
  }

  adjustedScore(score:number): number{
   let adjustment = lodash.find(this.cribReadingAdjustments.cribReadingAdjustment,{score:score});
  // return 2;
   return adjustment?.autoCallAdjustment ?? 0;
  }
  doAutoAdjustment(score:number, previousCall: string, item:any){
    item.score = score;
    if (this.useAutoCribAdjustments){
      let newCall = parseFloat(previousCall) + this.adjustedScore(score);
          item.call = parseFloat(newCall.toFixed(1));
      }
  }
 
  getChevronIcon(item: Models.CribReadingItem){
    let todays_allocation = this.getAllocationAmount(item);
    let cribfeedings = this.getcribHistoryFed(item.kraalId);
    if (cribfeedings.length >0){
    let yesterdays_allocation = cribfeedings[cribfeedings.length - 1];

    if (todays_allocation < yesterdays_allocation){
      if (todays_allocation > yesterdays_allocation - (yesterdays_allocation/10))
        return "mdi-chevron-down";
      else if (todays_allocation > yesterdays_allocation - (yesterdays_allocation/3.3))
        return "mdi-chevron-double-down";
      else
        return "mdi-chevron-triple-down";
      }
      else if (todays_allocation > yesterdays_allocation)
    {
      if (todays_allocation < yesterdays_allocation + (yesterdays_allocation/10))
        return "mdi-chevron-up";
      else if (todays_allocation < yesterdays_allocation + (yesterdays_allocation/3.3))
        return "mdi-chevron-double-up";
      else
        return "mdi-chevron-triple-up";
    }
    else {
      return "mdi-minus";
    }
    }
    

  }

  getcribHistoryFed(kraalId: string) {
    let history: any[] = [];
    for (let day of this.cribHistory){
    let fedAmount = 0;
      try{
        fedAmount = Math.round(lodash.find(day.cribScores, { kraalId: kraalId }).actualFed * lodash.find(day.cribScores, { kraalId: kraalId }).animalCount);
      }catch(err){
        console.warn(`Kraal ${kraalId} had no feed amount for day`)
      }
      history.push(fedAmount);
    }
    return history;
  }

 selectRow(item: any, select: (value: boolean) => void, isSelected: boolean, expand: (value: boolean) => void, isExpanded: boolean) {
    console.log(item);
    this.isEditing = false;
    this.$router.push(`/cribscores?selectedKraal=${item.kraalId}`);
  }

  save(item? :any) {
    if (item){
      this.open(item);
    }
    //update server with changes
    console.log("Save");
    let thisguid = this.open_guid;
    if ((thisguid.score > -1) && (thisguid.call != 0)){
      thisguid.status = "done";
    }
    if (thisguid.score > 5) return;
    delete thisguid.uploaded;
    delete thisguid.locationGuid;
    this.updateServer(thisguid);
  }
  open_guid?: any = undefined;
  cancel() {
    console.log("Cancel");
    this.snack = true;
    this.snackColor = "blue lighten-1";
    this.snackText = "Canceled";
   }
  open(guid?: string) {
    this.open_guid = guid;
    console.log("Open");
    console.log(guid);
  }
  close() {
    console.log("Close");
  }

  async updateServer(editedItem?: any) {
    console.log(editedItem);
    if (editedItem) {
      try {
        console.log("updateServer()");
        editedItem._send = "sending";
        this.forceRerender();
        let copy = lodash.cloneDeep(editedItem);
        delete copy._send;
        let guid_string = JSON.stringify(copy);
      
        let json = await this.$store.dispatch(
          "graphQl",
          {
            gql: `mutation AddCribReading($guid: String!, $input: CribReadingItemInput!) {
            addCribReading(guid: $guid, input: $input) 
          }`,
            variables: { guid: this.$store.state.user.location.guid, input: copy }
          },
          { root: true }
        );
        
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data saved";
        editedItem._send = "receivedByServer";
        this.forceRerender();
      } catch (err) {
        console.log(err);
        //TODO: revert data to original before editing if possible.
        //TODO: display error something could not save + reason why
        editedItem._send = "failedToSend";
        this.forceRerender();
        this.errorDialog = true;
        this.errorText = `Error saving to server: ${err} ${editedItem.kraalId}`;
        throw err;
      }
    }
  }

  graphQLSubscriber: any = undefined;
  graphQLUnsubscribe: any = undefined;

  async getCribPlansSocket(){
 if (this.$store.state.useP2PConn){
      await this.getOnlineCribPlansSocket();
    }
    else{
      await this.getOnsiteCribPlansSocket();
    }
  }
 
  async getOnsiteCribPlansSocket() {
    this.graphQLSubscriber = Subscriber.graphQLReconnectingSubscriber(
      this.urlws,
      this.options,
      (error: any, subscribe: any) => {
        try {
          if (!(error || subscribe)) {
            // Normal closure.
            console.log("connected to socket");
            this.$store.state.wsDialogVisible = false;
            return;
          }
          if (error) {
            console.log("error connecting to socket");
            if (error.event.type === "close") {
            } else {
              this.$store.state.wsDialogVisible = true;
              console.error(error);
            }
          }
          console.log("socket connecting ...");
          this.graphQLUnsubscribe = subscribe(this.subscriptions, this.variables, this.operationName, (error: any, data: any) => {
            if (!(error || subscribe)) {
              console.log("subscribe success");
              this.$store.state.wsDialogVisible = false;
              // Normal closure
              return;
            }
            if (error) {
              console.log("error subscribing");

              if (error.event.type === "close") {
              } else {
                this.$store.state.wsDialogVisible = true;
                console.error(error);
                //    throw error;
              }
            }
            this.$store.state.wsDialogVisible = false;
            console.log("receiving data");
         //   console.log(data);
            if (data?.cribScores?.cribScores?.length > 0){
            this.cribScores = data.cribScores.cribScores;
            for (let i = 0; i < this.cribScores.length; i++) {
              let itemPlan = lodash.find(this.feedPlan.FeedingPlanItems, { kraalId: this.cribScores[i].kraalId }); //TODO: replace kraal ID with Kraal guid
              this.cribScores[i].color = itemPlan?.currentRation?.colour;

              // let index = this.rationPlan.rationItems.findIndex(r => {
              //   return r.ration === this.cribScores.cribScores[i].ration;
              // });
              // if (index > -1) {
              //   this.cribScores.cribScores[i].color = this.rationPlan.rationItems[index].colour;
              // }
            }
            }
            this.loading = false;
          });
        } catch (err) {
          //show offline maybe
        }
      },
      5000,
      3,
      "graphql-ws"
    );
  }


  async getOnlineCribPlansSocket() {


    let variables = { guid: this.$store.state.user.location.guid, user: this.$store.state.user.user.uid };

    const subscribe = (subscriptions: string, variables: any, callback: (error: any, data: any) => Promise<void>) => {
      const client = createClient();
      let unsubscribe = client?.subscribe(
        {
          query: subscriptions,
          variables: variables,
        },
        {
          next: (message: any) => callback(undefined, message.data),
          error: (err: any) => {
            throw err;
          },
          complete: () => {console.log("subscription completed")},
        }
      );
      return unsubscribe;
    };

    this.graphQLUnsubscribe = subscribe(this.subscriptions, variables, async (error: any, data: any) => {
      this.$store.state.wsConnected = true;
      if (!(error || subscribe)) {
        console.log("subscribe success");
        this.$store.state.wsDialogVisible = false;
        // Normal closure
        return;
      }
      if (error) {
        console.log("error subscribing");

        this.$store.state.wsConnected = false;
        if (error.event.type === "close") {
        } else {
          this.$store.state.wsDialogVisible = true;
          console.error(error);
          //    throw error;
        }
      }
      this.$store.state.wsDialogVisible = false;
      this.$store.state.wsConnected = true;
      if (data?.cribScores?.cribScores?.length > 0){
            this.cribScores = data.cribScores.cribScores;
            for (let i = 0; i < this.cribScores.length; i++) {
              let itemPlan = lodash.find(this.feedPlan.FeedingPlanItems, { kraalId: this.cribScores[i].kraalId }); //TODO: replace kraal ID with Kraal guid
              this.cribScores[i].color = itemPlan?.currentRation?.colour;

              // let index = this.rationPlan.rationItems.findIndex(r => {
              //   return r.ration === this.cribScores.cribScores[i].ration;
              // });
              // if (index > -1) {
              //   this.cribScores.cribScores[i].color = this.rationPlan.rationItems[index].colour;
              // }
            }
            }
            this.loading = false;
    });
  }

async getCribPlanHistory() {
  try {
       this.loadingHistory = true;
        console.log("CribPlans()");

        let gql = `query CribScoreHistory($guid:String!){
        CribScoreHistory(guid:$guid) {
          typename
          guid
          date
          cribScores{
            typename
            guid
            score
            kraalId
            kraalGuid
            readingDate
            call
            status
            ration
            rationGuid
            actualFed
            color
            gender
            animalCount
            notes {
              typename
              guid
              timestamp
              text
            }
          }
        }
      }`;
      console.log(gql);
        let json2 = await this.$store.dispatch("graphQl", { gql, variables: { guid: this.$store.state.user.location.guid }, url: "https://pi.gigalot.systems:7777/feeder" });

        this.cribHistory = json2.data.CribScoreHistory;
        if (this.cribHistory?.length > 0){
          await this.$store.dispatch("data/clearCribScoreHistoryData");
          await this.$store.dispatch("data/addItemsToDb", {
          objectStoreName: "crib-scores-history",
          items: this.cribHistory
        });
        }
      console.log("Done Downloading History");
        this.loadingHistory = false;
      } catch (err) {
        console.log("getCribPlansHistory -> error: " + err);
        //TODO: Display error message for history
        this.$store.commit("popup/displayOk",  `Error: ${err.message}`);
      }
    }


  async getCribPlans() {
    this.loading = true;
    try {
      console.log("CribPlans()");

      let gql = `query($guid:String!){
         Rations(guid:$guid){
           guid
           dryMatterFactor{
            typename
            guid
            componentGuid
            dryMatterFactor
            history{
              value
              since
              }
            }
         }
         CribScores(guid:$guid) {
          typename
          guid
          date
          cribScores{
            typename
            guid
            score
            kraalId
            kraalGuid
            readingDate
            call
            status
            ration
            rationGuid
            sequence
            actualFed
            color
            gender
            animalCount
            notes {
              typename
              guid
              timestamp
              text
            }
          }
        }
        FeedPlan(guid:$guid) {
          FeedingPlanItems{
            kraalId
            currentRation{
              colour
            }
          }
        } 
        Configuration(guid:$guid)
        CribReadingAdjustments(guid:$guid) {
          typename
          guid
          useAliases
          useAdjustments
          callMoisture
          callonPreviousCall
          useCallAdjustmentCalculation
          cribReadingAdjustment{
            typename
            guid
            score
            userAlias
            autoCallAdjustment
          }
        }
        RationPlan(guid:$guid) {
          typename
          guid
          creationDate
          rationItems{
            typename
            guid
            ration
            rationGuid
            defaultDays
            colour
            order
          }
        }
        }`;
      let json = await this.$store.dispatch("graphQl", { gql, variables: { guid: this.$store.state.user.location.guid }, url: "https://pi.gigalot.systems:7777/feeder" });
      //  console.log(JSON.stringify(json.data));
      console.log("Done Downloading");
      this.feedPlan = json.data.FeedPlan;
      this.cribScores = json.data.CribScores.cribScores;
      this.rationPlan = json.data.RationPlans;
      this.rations = json.data.Rations;
      this.callIncrement = JSON.parse(json.data.Configuration).callIncrement;
      this.useCribAliases = JSON.parse(json.data.Configuration).useCribAliases;
      this.useAutoCribAdjustments = JSON.parse(json.data.Configuration).useCribAutoAdjustments;
      this.cribReadingAdjustments = json.data.CribReadingAdjustments;
      await this.$store.dispatch("data/addCribReadingAdjustments", this.cribReadingAdjustments);
      await this.$store.dispatch("data/addCallIncrement", this.callIncrement);
      await this.$store.dispatch("data/addItemsToDb", {
        objectStoreName: "ration-dmfs",
        items: this.rations
      });
    } catch (err) {
      console.log("getCribPlans -> error: " + err);
       this.$store.commit("popup/displayOk",  `Error: ${err.message}`);
    } finally {
      if (this.cribScores?.length > 0){

          for (let i = 0; i < this.cribScores.length; i++) {
        //get current ration colour
        let itemPlan = lodash.find(this.feedPlan.FeedingPlanItems, { kraalId: this.cribScores[i].kraalId }); //TODO: replace kraal ID with Kraal guid
        this.cribScores[i].color = itemPlan.currentRation.colour;

  
      }
      await this.$store.dispatch("data/clearCribScoreDownloadedData");
      console.log(`Writing cribscores to IDB ${this.cribScores.length}`);
      await this.$store.dispatch("data/addItemsToDb", {
        objectStoreName: "todays-crib-scores",
        items: this.cribScores
      });

      }
    
      this.loading = false;

    }
  }

  async getCribPlansDb() {
    this.loading = true;
    try {
      console.log("getCribPlansDb()");
      this.cribScores = await this.$store.dispatch("data/getTodaysCribScores");
      console.log("Done Downloading");
      this.loading = false;
      this.cribHistory = await this.$store.dispatch("data/getCribScoreHistory");
      this.rations = await this.$store.dispatch("data/getRationDMFs");
      this.callIncrement = parseFloat(await this.$store.dispatch("data/getCallIncrement"));
      this.loadingHistory = false;
    } catch (err) {
       this.$store.commit("popup/displayOk",  `Error: ${err.message}`);
      console.log("getCribPlans -> error: " + err);
      this.loading = false;
    }
  }

 beforeMount() {
    window.addEventListener("beforeunload", this.preventNav)
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
 }

  beforeRouteLeave(to:any, from:any, next:any) {
    if (this.isEditing) {
      if (!window.confirm("Leave without saving?")) {
        return;
      }
    }
    next();
  }
  isEditing= true;

  preventNav(event:any) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = "";
  }
}
